import {Link as ScrollLink} from 'react-scroll'
import {NavLink} from 'react-router-dom'
import ScrollToTopButton from './../platos/ScrollButton.js'

const HeaderAperitivo = () => {

  return (
    <div className='flex flex-col'>
      <ScrollToTopButton />
      <p className='text-black pb-6 sm:pb-0 text-2xl md:text-2xl lg:text-3xl font-bold text-center relative'>CARTA BEBESTIBLES<span className='translate-y-5 sm:translate-y-0 px-4 absolute right-0'><NavLink to='/platos' className='text-sm text-center font-bold text-brandColor2'>Carta de Platos</NavLink></span></p>
      {/* Otras partes de tu aplicación */}
        {/* Inicio Barra de navegación */}
      <nav className='bg-gray-200 p-4 grid lg:grid-cols-7 grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 justify-between w-full'>
          {/* Aperitivos Navbar */}
          <ScrollLink
            to='aperitivos-sec'
            spy={true}
            smooth={true}
            offset={-10}
            duration={800}
            className='p-1 text-center cursor-pointer'
          >
            Aperitivos
          </ScrollLink>
          {/* Bebidas y Jugos Navbar */}
          <ScrollLink
            to='bebidas-y-jugos'
            spy={true}
            smooth={true}
            offset={-10}
            duration={800}
            className='p-1 text-center cursor-pointer'
          >
            Bebidas y Jugos
          </ScrollLink>
          {/* Cervezas Navbar */}
          <ScrollLink
            to='cervezas'
            spy={true}
            smooth={true}
            offset={-10}
            duration={800}
            className='p-1 text-center cursor-pointer'
          >
            Cervezas
          </ScrollLink>
          {/* Vinos Navbar */}
          <ScrollLink
            to='vinos'
            spy={true}
            smooth={true}
            offset={-10}
            duration={800}
            className='p-1 text-center cursor-pointer'
          >
            Vinos
          </ScrollLink>
          {/* Bajativos Navbar */}
          <ScrollLink
            to='bajativos'
            spy={true}
            smooth={true}
            offset={-10}
            duration={800}
            className='p-1 text-center cursor-pointer'
          >
            Bajativos
          </ScrollLink>
          {/* Licores Navbar */}
          <ScrollLink
            to='licores'
            spy={true}
            smooth={true}
            offset={-10}
            duration={800}
            className='p-1 text-center cursor-pointer'
          >
            Licores
          </ScrollLink>
          {/* Cafetería Navbar */}
          <ScrollLink
            to='cafeteria'
            spy={true}
            smooth={true}
            offset={-10}
            duration={800}
            className='p-1 text-center cursor-pointer'
          >
            Cafetería 
          </ScrollLink>
        </nav>

        {/* Contenido Carta */}
      <div className="w-full pb-10">
          {/* Aperitivo */}
          <div id='aperitivos-sec' className='flex flex-col'>
            <p className='p-6 font-bold text-black text-lg'>Aperitivos</p>
            {/* Inicio Aperitivos de Carta */}
            <div className='grid lg:grid-cols-2 gap-y-20'>
              <div className='flex justify-between'>
                <p className='px-6'>Pisco Sour</p>
                <p className='px-6 text-brandColor3 font-bold'>$5.800</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Pisco Sour Catedral</p>
                <p className='px-6 text-brandColor3 font-bold'>$6.500</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Pisco Sour Frutal</p>
                <p className='px-6 text-brandColor3 font-bold'>$6.200</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Pisco Sour Frutal Catedral</p>
                <p className='px-6 text-brandColor3 font-bold'>$6.800</p>
             </div>
              <div className='flex justify-between'>
                <p className='px-6'>Whisky Sour</p>
                <p className='px-6 text-brandColor3 font-bold'>$7.200</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Amaretto Sour</p>
                <p className='px-6 text-brandColor3 font-bold'>$5.800</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Vaina</p>
                <p className='px-6 text-brandColor3 font-bold'>$5.200</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Algarrobina</p>
                <p className='px-6 text-brandColor3 font-bold'>$5.800</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Piña Colada</p>
                <p className='px-6 text-brandColor3 font-bold'>$6.200</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Chilcano</p>
                <p className='px-6 text-brandColor3 font-bold'>$6.200</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Chilcano Frutal</p>
                <p className='px-6 text-brandColor3 font-bold'>$6.500</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Piscola Peruana</p>
                <p className='px-6 text-brandColor3 font-bold'>$5.800</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Margarita</p>
                <p className='px-6 text-brandColor3 font-bold'>$4.800</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Margarita Frutal</p>
                <p className='px-6 text-brandColor3 font-bold'>$5.200</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Tequila Sunrise</p>
                <p className='px-6 text-brandColor3 font-bold'>$5.200</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Caipiriña 51°</p>
                <p className='px-6 text-brandColor3 font-bold'>$4.800</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Caipiriña Frutal</p>
                <p className='px-6 text-brandColor3 font-bold'>$5.200</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Caipiroska</p>
                <p className='px-6 text-brandColor3 font-bold'>$5.200</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Mojito Cubano</p>
                <p className='px-6 text-brandColor3 font-bold'>$5.200</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Mojito Frutal</p>
                <p className='px-6 text-brandColor3 font-bold'>$5.800</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Daikiri</p>
                <p className='px-6 text-brandColor3 font-bold'>$4.900</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Daikiri Frutal</p>
                <p className='px-6 text-brandColor3 font-bold'>$5.200</p>
              </div>
            </div>
            {/* Fin Aperitivos de Carta */}
          </div>
          {/* Bebidas y Jugos */}
          <div id='bebidas-y-jugos'>
            <p className='px-6 pb-10 pt-20 font-bold text-black text-lg'>Bebidas y Jugos</p>
            {/* Inicio Bebidas y Jugos de Carta*/}
            <div className='grid lg:grid-cols-2 gap-y-20'>
              <div className='flex justify-between'>
                <p className='px-6'>Inca Kola 1L ½</p>
                <p className='px-6 text-brandColor3 font-bold'>$5.800</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Inca Kola ½</p>
                <p className='px-6 text-brandColor3 font-bold'>$2.800</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Bebidas Personales</p>
                <p className='px-6 text-brandColor3 font-bold'>$2.600</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Limonada Mi Tierra</p>
                <p className='px-6 text-brandColor3 font-bold'>$4.200</p>
             </div>
              <div className='flex justify-between'>
                <p className='px-6'>Limonada</p>
                <p className='px-6 text-brandColor3 font-bold'>$3.800</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Chicha Morada</p>
                <p className='px-6 text-brandColor3 font-bold'>$4.200</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Jugos (Mango, Maracuya, Piña)</p>
                <p className='px-6 text-brandColor3 font-bold'>$4.500</p>
              </div>
            {/* Fin Bebidas y Jugos de Carta*/}
            </div>
          </div>
          {/* Cervezas */}
          <div id='cervezas'>
            <p className='px-6 pb-10 pt-20 font-bold text-black text-lg'>Cervezas</p>
            <p className='px-6 pb-6 font-bold text-neutral-700 text-md'>Importadas</p>
            {/* Inicio Cervezas de Carta */}
            {/* Importadas */}
            <div className='grid lg:grid-cols-2 gap-y-20'>
              <div className='flex justify-between'>
                <p className='px-6'>Cusqueña Lager</p>
                <p className='px-6 text-brandColor3 font-bold'>$4.200</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Cusqueña Negra</p>
                <p className='px-6 text-brandColor3 font-bold'>$4.200</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Corona</p>
                <p className='px-6 text-brandColor3 font-bold'>$4.000</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Heineken</p>
                <p className='px-6 text-brandColor3 font-bold'>$4.200</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Royal</p>
                <p className='px-6 text-brandColor3 font-bold'>$4.000</p>
              </div>
            </div>
            <p className='px-6 pb-6 pt-20 font-bold text-neutral-700 text-md'>Nacional</p>
            {/* Nacionales */}
            <div className='grid lg:grid-cols-2 gap-y-20'>
              <div className='flex justify-between'>
                <p className='px-6'>Kunstman Torobayo</p>
                <p className='px-6 text-brandColor3 font-bold'>$4.200</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Austral Calafate</p>
                <p className='px-6 text-brandColor3 font-bold'>$4.200</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Michelada</p>
                <p className='px-6 text-brandColor3 font-bold'>$1.500</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Chelada</p>
                <p className='px-6 text-brandColor3 font-bold'>$1.200</p>
              </div>
            </div>
            {/* Fin Cervezas de Carta */}
          </div>
          {/* Vinos */}
          <div id='vinos'>
            <p className='px-6 pb-10 pt-20 font-bold text-black text-lg'>Vinos <span className='font-bold text-neutral-700 text-md'>(Cepas Blancas)</span></p>
            {/* Vinos Blancos de Carta */}
            <div className='grid lg:grid-cols-2 gap-y-20'>
              <div className='flex justify-between'>
                <p className='px-6'>Santa Ema Sauvignon Blanc</p>
                <p className='px-6 text-brandColor3 font-bold'>$10.900</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Misiones del Rengo Sauvignon Blanc</p>
                <p className='px-6 text-brandColor3 font-bold'>$10.900</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Santa Ema Chardonnay</p>
                <p className='px-6 text-brandColor3 font-bold'>$10.900</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Casillero del Diablo Chardonnay</p>
                <p className='px-6 text-brandColor3 font-bold'>$10.900</p>
              </div>
            </div>
            <p className='px-6 pb-6 pt-20 font-bold text-neutral-700 text-md'>½ Botella</p>
            <div className='grid lg:grid-cols-2 gap-y-20'>
              <div className='flex justify-between'>
                <p className='px-6'>Santa Ema Sauvignon Blanc</p>
                <p className='px-6 text-brandColor3 font-bold'>$6.900</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Misiones del Rengo Sauvignon Blanc</p>
                <p className='px-6 text-brandColor3 font-bold'>$6.900</p>
              </div>
            </div>
            <p className='px-6 pb-6 pt-20 font-bold text-neutral-700 text-md'>Espumantes</p>
            <div className='grid lg:grid-cols-2 gap-y-20'>
              <div className='flex justify-between'>
                <p className='px-6'>Valdivieso Brut</p>
                <p className='px-6 text-brandColor3 font-bold'>9.900</p>
              </div>
            </div>
            <p className='px-6 pb-10 pt-20 font-bold text-black text-lg'>Vinos <span className='font-bold text-neutral-700 text-md'>(Cepas Tintas)</span></p>
            {/* Vinos Blancos de Carta */}
            <div className='grid lg:grid-cols-2 gap-y-20'>
              <div className='flex justify-between'>
                <p className='px-6'>Santa Ema Cabernet Sauvignon</p>
                <p className='px-6 text-brandColor3 font-bold'>$10.200</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Casillero del Diablo Cabernet Sauvignon</p>
                <p className='px-6 text-brandColor3 font-bold'>$10.900</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Casas Patronales Cabernet Sauvignon</p>
                <p className='px-6 text-brandColor3 font-bold'>$13.900</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Santa Digna Reserva Carmenere Sauvignon</p>
                <p className='px-6 text-brandColor3 font-bold'>$14.900</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Santa Digna Reserva Carmenere</p>
                <p className='px-6 text-brandColor3 font-bold'>$14.900</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Santa Ema Carmenere</p>
                <p className='px-6 text-brandColor3 font-bold'>$10.900</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Santa Ema Merlot</p>
                <p className='px-6 text-brandColor3 font-bold'>$10.900</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Toro de Piedra</p>
                <p className='px-6 text-brandColor3 font-bold'>$14.990</p>
              </div>
            </div>
            <p className='px-6 pb-6 pt-20 font-bold text-neutral-700 text-md'>½ Botella</p>
            <div className='grid lg:grid-cols-2 gap-y-20'>
              <div className='flex justify-between'>
                <p className='px-6'>Santa Ema Cabernet Sauvignon</p>
                <p className='px-6 text-brandColor3 font-bold'>$6.900</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Misiones de Rengo Cabernet Sauvignon</p>
                <p className='px-6 text-brandColor3 font-bold'>$6.900</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Santa Ema Carmenere</p>
                <p className='px-6 text-brandColor3 font-bold'>$6.900</p>
              </div>
            </div>
          </div>
          {/* Bajativos */}
          <div id='bajativos'>
            <p className='px-6 pb-10 pt-20 font-bold text-black text-lg'>Bajativos</p>
            {/* Inicio Bajativos de Carta */}
            <div className='grid lg:grid-cols-2 gap-y-20'>
              <div className='flex justify-between'>
                <p className='px-6'>Whisky Jack Daniel's</p>
                <p className='px-6 text-brandColor3 font-bold'>$8.200</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Whisky Red Label</p>
                <p className='px-6 text-brandColor3 font-bold'>$7.200</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Whisky Chivas Regal</p>
                <p className='px-6 text-brandColor3 font-bold'>$7.800</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Whisky Ballantines</p>
                <p className='px-6 text-brandColor3 font-bold'>$6.500</p>
              </div>
            </div>
            {/* Fin Bajativos de Carta */}
          </div>
          {/* Licores */}
          <div id='licores'>
            <p className='px-6 pb-10 pt-20 font-bold text-black text-lg'>Licores</p>
            {/* Inicio Licores de Carta */}
            <div className='grid lg:grid-cols-2 gap-y-20'>
              <div className='flex justify-between'>
                <p className='px-6'>Fernet Branca</p>
                <p className='px-6 text-brandColor3 font-bold'>$2.500</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Amaretto</p>
                <p className='px-6 text-brandColor3 font-bold'>$2.000</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Baileys</p>
                <p className='px-6 text-brandColor3 font-bold'>$3.800</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Menta</p>
                <p className='px-6 text-brandColor3 font-bold'>$2.000</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Manzanilla</p>
                <p className='px-6 text-brandColor3 font-bold'>$2.000</p>
              </div>
            </div>
            {/* Fin Licores de Carta */}
          </div>
          {/* Cafetería */}
          <div id='cafeteria'>
            <p className='px-6 pb-10 pt-20 font-bold text-black text-lg'>Cafetería</p>
            {/* Inicio Cafeteria de Carta */}
            <div className='grid lg:grid-cols-2 gap-y-20'>
              <div className='flex justify-between'>
                <p className='px-6'>Café Express</p>
                <p className='px-6 text-brandColor3 font-bold'>$2.500</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Café Express Doble</p>
                <p className='px-6 text-brandColor3 font-bold'>$3.500</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Café Cortado</p>
                <p className='px-6 text-brandColor3 font-bold'>$2.900</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Café Cortado Doble</p>
                <p className='px-6 text-brandColor3 font-bold'>$4.200</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Té</p>
                <p className='px-6 text-brandColor3 font-bold'>$2.500</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Agua de Hierbas</p>
                <p className='px-6 text-brandColor3 font-bold'>$2.800</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Nescafé Pequeño</p>
                <p className='px-6 text-brandColor3 font-bold'>$2.800</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Nescafé Grande</p>
                <p className='px-6 text-brandColor3 font-bold'>$3.800</p>
              </div>

            </div>
            {/* Fin Cafetería  de Carta */}
          </div>
        </div>
        {/* Fin del contenido scrollable */}
      {/* Otras partes de tu aplicación */}
    </div>
  );
}

export default HeaderAperitivo;
