import {NavLink} from 'react-router-dom'


function MenuSeccion() {
  return(
    <main className='bg-customBg'>
      <hr className='border-stone-900' />

      <div className='flex flex-col items-center lg:flex-row justify-between py-40 mx-14 h-full'> 
        <div className='flex flex-col p-2 '>
          <p className='text-2xl font-neueMontreal text-gray-300 border-b-2'>MENÚ EJECUTIVO</p>
          <p className='font-light text-gray-300 py-4'>Contamos con la variedad para tu jornada laboral</p>
          <NavLink to='/menu' className='p-4 text-customBg text-center font-neueMontreal bg-white'>
            Mas Información
          </NavLink>
        </div>
        {/* Img */}
        <div className=''>
        </div>
      </div>

    </main>
  );
}


export default MenuSeccion
