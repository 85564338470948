//IMPORTS
import { BrowserRouter as Router } from 'react-router-dom';
import store from './store';
import { Helmet, HelmetProvider } from 'react-helmet-async'; //To SEO
import { Provider } from 'react-redux';
import AnimatedRoutes from 'Routes';



// MAIN APP
function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Mi Tierra | Restaurant</title>
        <meta name="description" content="Disfruta de la gastronomía peruana en Santiago y vive de una experiencia agradable y familiar. Traemos los mejores platos del Perú con una sazón auténtica para que puedas degustar." />
        <meta name="keywords" content='restaurant, restaurant peruano, restaurant peruano en chile, restaurant peruano en santiago, restaurant peruano en conchali, gastronomia peruana en chile, gastronomia internacional en santiago, restobar en santiago, restobar en conchali, comida peruana en chile, comida peruana en santiago de chile, comida peruana en conchali, platos de comida peruanos, platos de comida peruanos en chile, restaurantes peruanos en santiago, comida peruana para servir, comida peruana para llevar' />
        <meta name="robots" content='all' />
        <link rel="canonical" href="http://www.restaurantmitierra.cl/" />
        <meta name="author" content='Mi Tierra' />
        <meta name="publisher" content='Mi Tierra' />

        {/* Social Media Tags */}
        <meta property="og:title" content='Mi Tierra | Restaurant' />
        <meta property="og:description" content='Disfruta de la gastronomía peruana en Santiago y vive de una experiencia agradable y familiar. Traemos los mejores platos del Perú con una sazón auténtica para que puedas degustar.' />
        <meta property="og:url" content="http://www.restaurantmitierra.cl/" />
        <meta property="og:image" content='%PUBLIC_URL%/mi_tierra_logo.JPG' />

        <meta name="twitter:title" content='Mi Tierra | Restaurant' />
        <meta
            name="twitter:description"
            content='La mejor comida peruana en Santiago'
        />
        <meta name="twitter:image" content='%PUBLIC_URL%/mi_tierra_logo.JPG' />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Provider store={store}>
        <Router>
            <AnimatedRoutes/>
        </Router>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
