import { Helmet } from 'react-helmet'

function Guarantee() {
  return(
    <main className='py-20 bg-black'>
      <Helmet>
        <script src="https://static.elfsight.com/platform/platform.js" data-use-service-core defer></script>
      </Helmet>
      <div className="elfsight-app-036382c5-75cc-444c-b60c-b9027224eef0" data-elfsight-app-lazy></div>
    </main>
  );
}

export default Guarantee
