import {Link as ScrollLink} from 'react-scroll'
import {NavLink} from 'react-router-dom'
import ScrollToTopButton from './ScrollButton.js'

const HeaderEntrada = () => {

  return (
    <div className='flex flex-col'>
      <ScrollToTopButton />
      <p className='text-black pb-6 sm:pb-0 text-2xl md:text-2xl lg:text-3xl font-bold text-center relative'>CARTA PLATOS<span className='translate-y-5 sm:translate-y-0 px-4 absolute right-0'><NavLink className='text-sm text-center font-bold text-brandColor2' to='/bebestibles'>Carta de Bebestibles</NavLink></span></p>
        {/* Inicio Barra de navegación */}
      <nav className='bg-gray-200 p-4 grid lg:grid-cols-8 grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 justify-between w-full'>
          {/* Entradas Navbar */}
          <ScrollLink
            to='entradas-sec'
            spy={true}
            smooth={true}
            offset={-10}
            duration={800}
            className='p-1 text-center cursor-pointer'
          >
            Entradas
          </ScrollLink>
          {/* Pescados Navbar */}
          <ScrollLink
            to='pescados'
            spy={true}
            smooth={true}
            offset={-10}
            duration={800}
            className='p-1 text-center cursor-pointer'
          >
            Pescados
          </ScrollLink>
          {/* Mariscos y Carnes Navbar */}
          <ScrollLink
            to='mariscos-y-carnes'
            spy={true}
            smooth={true}
            offset={-10}
            duration={800}
            className='p-1 text-center cursor-pointer'
          >
            Mariscos y Carnes 
          </ScrollLink>
          {/* Pollo Navbar */}
          <ScrollLink
            to='pollo'
            spy={true}
            smooth={true}
            offset={-10}
            duration={800}
            className='p-1 text-center cursor-pointer'
          >
            Pollo
          </ScrollLink>

          {/* Arroz Navbar */}
          <ScrollLink
            to='arroz'
            spy={true}
            smooth={true}
            offset={-10}
            duration={800}
            className='p-1 text-center cursor-pointer'
          >
            Arroz
          </ScrollLink>
          {/* Pastas Navbar */}
          <ScrollLink
            to='pastas'
            spy={true}
            smooth={true}
            offset={-10}
            duration={800}
            className='p-1 text-center cursor-pointer'
          >
            Pastas
          </ScrollLink>
          {/* Sopas Navbar */}
          <ScrollLink
            to='sopas'
            spy={true}
            smooth={true}
            offset={-10}
            duration={800}
            className='p-1 text-center cursor-pointer'
          >
            Sopas
          </ScrollLink>
          {/* Ensaladas y Postres Navbar */}
          <ScrollLink
            to='ensaladas-y-postres'
            spy={true}
            smooth={true}
            offset={-10}
            duration={800}
            className='p-1 text-center cursor-pointer'
          >
            Ensaladas y Postres
          </ScrollLink>
        </nav>

        {/* Contenido Carta */}
        <div className="w-full pb-10">
          {/* Entrada */}
          <div id='entradas-sec' className='flex flex-col'>
            <p className='p-6 font-bold text-black text-lg'>Entradas</p>
            <p className='px-6 pb-6 font-bold text-neutral-700 text-md'>Entradas Calientes</p>
            {/* Inicio Entradas de Carta */}
            <div className='grid lg:grid-cols-2 sm:grid-cols-1 gap-y-20'>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Chicharrón de Calamar</p>
                  <p className='px-6 font-light text-sm'>Anillos de calamar acompañados de papas doradas y salsa
tártara.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$11.800</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Chicharrón de Pescado</p>
                  <p className='px-6 font-light text-sm'>Trozos de pescado apanados acompañados con papas
doradas, zarza criolla y salsa tártara.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$12.900</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Chicharrón de Pollo</p>
                  <p className='px-6 font-light text-sm'>Trozos de pollo apanados acompañados de papas doradas
zarza criolla y salsa tártara.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$11.900</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Chicharrones Mixtos</p>
                  <p className='px-6 font-light text-sm'>Mixtura de mariscos apanados acompañados con papas
doradas, zarza criolla y salsa tártara.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$13.900</p>
             </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Camarones Apanados</p>
                  <p className='px-6 font-light text-sm'>Camarones apanados acompañados con papas doradaszarza
criolla y una salsa tártara.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$13.900</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Jalea Norteña Familiar (3 personas)</p>
                  <p className='px-6 font-light text-sm'>Trozos de pescado, pulpo, camarón y calamar apanados,
acompañados con salsa criolla, choclo peruano, papas y
yucas doradas.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$33.900</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Costumbres de mi Tierra (3 personas)</p>
                  <p className='px-6 font-light text-sm'>Especial plato de la casa con exquisitas brochetas mixtas con
pollo, carne y camarones, acompañados de ricos chicharrones
mixtos con papas fritas.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$33.900</p>
              </div>
            </div>
            <p className='px-6 pb-6 font-bold text-neutral-700 text-md pt-10'>Entradas Frías</p>
            {/* Inicio Entradas de Carta */}
            <div className='grid lg:grid-cols-2 sm:grid-cols-1 gap-y-20'>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Leche de Tigre</p>
                  <p className='px-6 font-light text-sm'>Delicioso extracto de clásico ceviche peruano que opera como un apetitoso coctel marino.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$8.500</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Ceviche de Pescado</p>
                  <p className='px-6 font-light text-sm'>Cubos de pescado del día con cebolla morada, camote, choclo peruano y cilantro</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$13.500</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Ceviche Mixto</p>
                  <p className='px-6 font-light text-sm'>Cubitos de pescado y mariscos marinados en jugo de limón, acompañados de choclo peruano, cancha y
camote.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$13.900</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Ceviche Mi Tierra</p>
                  <p className='px-6 font-light text-sm'>Cubitos de pescado y camarones marinados en jugo de limón con pasta amarilla, acompañados con
chicharrón de pescado, choclo peruano y camote.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$14.200</p>
             </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Ceviche Mi Tierra Especial</p>
                  <p className='px-6 font-light text-sm'>Finos trozos de filete de salmón acompañados con camarones, trozos de pescado de reineta, cebolla
morada, camote y choclo peruano.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$14.900</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Pulpo Olivar</p>
                  <p className='px-6 font-light text-sm'>Finas láminas de pulpo marinadas en jugo de limón bañados en salsa de aceituna peruana.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$12.900</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Papas a la Huancaína</p>
                  <p className='px-6 font-light text-sm'>Rodajas de papas sancochadas bañadas en crema de ají amarillo.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$7.800</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Causa de Camarones</p>
                  <p className='px-6 font-light text-sm'>Suave puré de papa amarilla peruana, prensada con ají amarillo y especies, rellena con camarones
ecuatorianos, palta y salsa golf.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$10.200</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Causa de Pollo</p>
                  <p className='px-6 font-light text-sm'>Suave puré de papa amarilla peruana prensada con ají amarillo y especies, rellena con pollo en juliana,
palta y mayonesa casera.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$8.900</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Piqueo Frío Marino (3 personas)</p>
                  <p className='px-6 font-light text-sm'>Ceviche tradicional (pesca del día), pulpo olivar, causa de camarón, tiradito mixto y acompañado con una
copa de leche de tigre.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$33.900</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Causa Cevichada</p>
                  <p className='px-6 font-light text-sm'>Nuestra Causa de la casa rellena con camarones acompañado con un ceviche tradicional peruano</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$13.900</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Trilogía de Ceviche</p>
                  <p className='px-6 font-light text-sm'>Un exquisito plato peruano el cual trae camarones con su salsa de rocoto, mixtura de mariscos en salsa de cilantro y pescados acompañados de un suave ají amarillo.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$13.900</p>
              </div>
            </div>
            {/* Fin Entradas de Carta */}
          </div>
          {/* Pescados */}
          <div id='pescados'>
            <p className='px-6 pb-10 pt-20 font-bold text-black text-lg'>Pescados</p>
            {/* Inicio Pescados de Carta*/}
            <div className='grid lg:grid-cols-2 gap-y-20'>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Pescado a lo Macho</p>
                  <p className='px-6 font-light text-sm'>Filete de pescado a la plancha bañado en salsa de mariscos, acompañado
de arroz blanco y rodajas de papas doradas.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$13.600</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Salmón a la francesa</p>
                  <p className='px-6 font-light text-sm'>Filete de salmón bañado de una exquisita salsa bechamel, acompañado
de pulpo, espárragos y arroz blanco.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$13.900</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Congrio en Salsa de Mariscos</p>
                  <p className='px-6 font-light text-sm'>Medallón de congrio grillado, bañado en una deliciosa salsa de
mariscos, con guarnición de arroz blanco.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$14.600</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Congrio Mi Tierra</p>
                  <p className='px-6 font-light text-sm'>Medallón de congrio a la plancha bañado en una deliciosa salsa bechamel
con camarones y champiñones, con guarnición de arroz blanco.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$14.900</p>
             </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Pachamanca Marina</p>
                  <p className='px-6 font-light text-sm'>Filete de pescado a la plancha, acompañado de una deliciosa mixtura de
mariscos y salsa criolla, bañado en salsa huacatay, servido con bastón de
yucas fritas y papas doradas.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$13.500</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Salmón a lo Macho</p>
                  <p className='px-6 font-light text-sm'>Filete de salmón a la plancha bañado en salsa de mariscos, acompañado
de arroz blanco y papas cocidas.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$14.200</p>
              </div>
            {/* Fin Pescados de Carta*/}
            </div>
          </div>
          {/* Mariscos y Carnes */}
          <div id='mariscos-y-carnes'>
            <p className='px-6 pb-10 pt-20 font-bold text-black text-lg'>Mariscos y Carnes</p>
            {/* Inicio Mariscos y Carnes de Carta */}
            <div className='grid lg:grid-cols-2 gap-y-20'>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Picante de Mariscos</p>
                  <p className='px-6 font-light text-sm'>Mixtura de mariscos flameados en vino blanco, salsa de coral y camarones, acompañado de arroz blanco y zarza criolla.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$13.200</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Picante de Camarones</p>
                  <p className='px-6 font-light text-sm'>Camarones saltados en vino blanco con una salsa de coral y camarones, acompañado de zarza criolla y arroz blanco.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$13.800</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Tacu Tacu de Mariscos</p>
                  <p className='px-6 font-light text-sm'>Mezcla de arroz con frijoles al sartén con salsa cremosa y mixtura de mariscos.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$13.800</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Dúo Marino</p>
                  <p className='px-6 font-light text-sm'>Tradicional ceviche mixto y arroz con mariscos.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$13.900</p>
             </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Risotto a la Huancaína con Camarones</p>
                  <p className='px-6 font-light text-sm'>Excelente risotto cremoso con especies y nuestra salsa huancaína, con camarones saltados en vino blanco.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$14.200</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Pulpo a la Parrilla</p>
                  <p className='px-6 font-light text-sm'>200gr de pulpo y especies peruanas servido con papas al romero y servido de zarza criolla.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$15.900</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Filete Mar y Tierra</p>
                  <p className='px-6 font-light text-sm'>Delicioso filete de vacuno a la plancha bañado con salsa de camarones, flameados con vino blanco al mejor estilo
peruano, montado sobre un rico risotto al apio.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$14.200</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Filete en Salsa a la Pimienta</p>
                  <p className='px-6 font-light text-sm'>Tierno filete de vacuno a la plancha cubierto con salsa de pimienta negra y acompañado con papas fritas y arroz.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$13.900</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Filete Miñón</p>
                  <p className='px-6 font-light text-sm'>Filete de miñón en salsa de vino tinto con champiñones y tocino, acompañado de una porción de arroz.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$13.900</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Lomo Saltado</p>
                  <p className='px-6 font-light text-sm'>Trozos de filete saltados con cebolla, tomate y especies peruanas, servido con papas fritas y arroz blanco.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$13.900</p>
             </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Lomo a lo Macho</p>
                  <p className='px-6 font-light text-sm'>Filete de vacuno a la plancha cubierto en salsa de mariscos a base de ajíes peruanos, acompañado de arroz blanco.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$14.200</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Tacu Tacu de Lomo Saltado</p>
                  <p className='px-6 font-light text-sm'>Tamal de frijoles criollos acompañado de nuestro tradicional lomo saltado.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$14.200</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Seco de Vacuno</p>
                  <p className='px-6 font-light text-sm'>Carne guisada en salsa de cilantro acompañado de porotos, arroz blanco y zarza criolla.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$13.500</p>
             </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Risotto a la Huancaína con Lomo Saltado</p>
                  <p className='px-6 font-light text-sm'>Trozos de filetes saltados con cebolla, tomate y especies peruanas, servido con un
rico risotto cremoso y la tradicional salsa huancaína.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$14.200</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Pachamanca Mar y Tierra</p>
                  <p className='px-6 font-light text-sm'>Filete a la plancha, acompañado de una deliciosa mixtura de mariscos con papa dorada y yuca.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$14.200</p>
              </div>
            </div>
            {/* Fin Mariscos y Carnes de Carta */}
          </div>
          {/* Pollo */}
          <div id='pollo'>
            <p className='px-6 pb-10 pt-20 font-bold text-black text-lg'>Pollo</p>
            {/* Arroz Blancos de Carta */}
            <div className='grid lg:grid-cols-2 gap-y-20'>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Pechuga a la Plancha</p>
                  <p className='px-6 font-light text-sm'>Filete de pechuga de pollo a la plancha grillado y servido con papas fritas
y ensalada.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$10.200</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Ají de Gallina</p>
                  <p className='px-6 font-light text-sm'>Pechuga de pollo deshilachado con rodajas de papas cocidas
acompañados con arroz blanco.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$10.200</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Pollo Saltado</p>
                  <p className='px-6 font-light text-sm'>Trozos de pechuga de pollo, saltados con cebolla y tomate, salsa
de soya, acompañado de papas fritas y arroz blanco</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$11.200</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Suprema de Pollo</p>
                  <p className='px-6 font-light text-sm'>Filete de pechuga de pollo apanado, servido con papas fritas y ensalada.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$10.600</p>
             </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Plato Kids</p>
                  <p className='px-6 font-light text-sm'>Deliciosas vienesas en forma de pulpitos, acompañados de papas fritas.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$8.900</p>
              </div>
            </div>
            <p className='px-6 pb-6 pt-20 font-bold text-neutral-700 text-md'>Pollo a la Brasa</p>
            <div className='grid lg:grid-cols-2 gap-y-20'>
              <div className='flex justify-between'>
                <p className='px-6'>1 pollo + Ensalada + Papas Fritas + Bebida 1 1⁄2 Inca</p>
                <p className='px-6 text-brandColor3 font-bold'>$26.900</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>1Pollo + Ensalada y Papas fritas</p>
                <p className='px-6 text-brandColor3 font-bold'>$22.500</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>1⁄2 Pollo + Ensalada y Papas fritas.</p>
                <p className='px-6 text-brandColor3 font-bold'>$13.500</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>1⁄4 Pollo + Ensalada y Papas Fritas</p>
                <p className='px-6 text-brandColor3 font-bold'>$8.200</p>
              </div>
            </div>
          </div>

          {/* Arroz */}
          <div id='arroz'>
            <p className='px-6 pb-10 pt-20 font-bold text-black text-lg'>Arroz</p>
            {/* Arroz Blancos de Carta */}
            <div className='grid lg:grid-cols-2 gap-y-20'>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Arroz Chaufa de Pollo</p>
                  <p className='px-6 font-light text-sm'>Arroz saltado con trozos de pollo, aceite sésamo, cebollín, pimiento rojo, diente
de dragón y salsa de soya.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$12.900</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Arroz Chaufa de Carne</p>
                  <p className='px-6 font-light text-sm'>Arroz saltado con trozos de carne, aceite sésamo, cebollín, pimiento rojo,
diente dedragón y salsa de soya.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$13.200</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Arroz Chaufa de Camarones</p>
                  <p className='px-6 font-light text-sm'>Arroz saltado de camarones, aceite de sésamo, cebollín pimiento rojo,diente
de dragón y salsa de soya.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$13.500</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Arroz Chaufa Tres Sabores</p>
                  <p className='px-6 font-light text-sm'>Combinación de arroz con pollo, carne, camarones al wok con salsa de soya
y sésamo.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$13.900</p>
             </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Arroz con Mariscos</p>
                  <p className='px-6 font-light text-sm'>Mixtura de mariscos en rica salsa de coral de camarones servido con salsa
criolla.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$13.900</p>
              </div>
            </div>
          </div>
          </div>
          {/* Pastas */}
          <div id='pastas'>
            <p className='px-6 pb-10 pt-20 font-bold text-black text-lg'>Pastas</p>
            {/* Inicio Pastas de Carta */}
            <div className='grid lg:grid-cols-2 gap-y-20'>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Fetuccini Alfredo</p>
                  <p className='px-6 font-light text-sm'>Delicioso fetuccini bañado con nuestra rica salsa bechamel y jamón.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$8.900</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Fetuccini Vegetariano</p>
                  <p className='px-6 font-light text-sm'>Fetuccini salteado con una selección de verduras tiernas con aceite de oliva y
punto de vino blanco.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$10.600</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Fetuccini Saltado Tres Sabores</p>
                  <p className='px-6 font-light text-sm'>Mixtura de ave, vacuno y camarones saltados al wok con selección de
verduras ensalsa de soya y sésamo.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$13.900</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Fetuccini a Lo Macho</p>
                  <p className='px-6 font-light text-sm'>Mixtura de mariscos en una deliciosa salsa de coral adornado con queso
parmesano.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$13.900</p>
             </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Fetuccini Saltado de Pollo</p>
                  <p className='px-6 font-light text-sm'>Trozos de pechuga saltados con espagueti, tomate, cebolla, selección de
verduras ysalsa de soya.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$12.800</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Fetuccini Saltado de Carne</p>
                  <p className='px-6 font-light text-sm'>Trozos de filete saltados con espagueti, cebolla, tomate, verduras y salsa de
soya.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$13.200</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Fetuccini a la huancaína con lomo saltado</p>
                  <p className='px-6 font-light text-sm'>Trozos de filete de vacuno salteado en cebolla, tomate y especies al lado de
fetuccini y crema a la huancaína.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$15.900</p>
              </div>
            </div>

            {/* Fin Pastas de Carta */}
          </div>
          {/* Sopas */}
          <div id='sopas'>
            <p className='px-6 pb-10 pt-20 font-bold text-black text-lg'>Sopas</p>
            {/* Inicio Sopas de Carta */}
            <div className='grid lg:grid-cols-2 gap-y-20'>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Sudado de Pescado</p>
                  <p className='px-6 font-light text-sm'>Fume de pescado con especies peruanas, vino blanco, yuca, un toque de chicha de
jora acompañado con arroz blanco.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$13.200</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Parihuela</p>
                  <p className='px-6 font-light text-sm'>Fume de pescado y mixtura de mariscos.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$12.900</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Chupe de Camarones</p>
                  <p className='px-6 font-light text-sm'>Bisque de camarones, especies con un toque de vino blanco y un huevo</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$14.200</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Sopa a la Criolla</p>
                  <p className='px-6 font-light text-sm'>Consomé de res en trozos, fideo cabello de ángel en salsa de coral y camarón, leche
evaporada con toque de orégano y cilantro.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$13.200</p>
             </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Dieta de Pollo</p>
                  <p className='px-6 font-light text-sm'>Fondo de ave, trozos de pollo, arveja, papa, fideo cabello de ángel y orégano.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$10.800</p>
              </div>
            </div>
            {/* Fin Sopas de Carta */}
          </div>
          {/* Ensaladas */}
          <div id='ensaladas-y-postres'>
            <p className='px-6 pb-10 pt-20 font-bold text-black text-lg'>Ensaladas y Postres</p>
            {/* Inicio Ensaladas de Carta */}
            <div className='grid lg:grid-cols-2 gap-y-20'>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Ensalada mixta</p>
                  <p className='px-6 font-light text-sm'>Rica ensalada de lechuga, tomate cebolla, pepinillo, aceituna y huevo.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$5.200</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Ensalada César</p>
                  <p className='px-6 font-light text-sm'>Deliciosa ensalada de lechuga, pollo a la plancha, crotones y queso
parmesano</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$5.800</p>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col'>
                  <p className='px-6'>Ensalada a la Griega</p>
                  <p className='px-6 font-light text-sm'>Lechuga, tomate, cebolla morada, pimiento, queso fresco, aceituna y
orégano.</p>
                </div>
                <p className='px-6 text-brandColor3 font-bold'>$5.800</p>
              </div>
            </div>
            <p className='px-6 pb-6 pt-20 font-bold text-neutral-700 text-md'>Postres</p>
            <div className='grid lg:grid-cols-2 gap-y-20 pb-10'>
              <div className='flex justify-between'>
                <p className='px-6'>Crema Volteada</p>
                <p className='px-6 text-brandColor3 font-bold'>$4.200</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Tiramisú</p>
                <p className='px-6 text-brandColor3 font-bold'>$4.900</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Suspiro Limeño</p>
                <p className='px-6 text-brandColor3 font-bold'>$4.900</p>
              </div>
              <div className='flex justify-between'>
                <p className='px-6'>Cheesecake De Maracuya</p>
                <p className='px-6 text-brandColor3 font-bold'>$5.200</p>
              </div>
            {/* Fin Ensaladas  de Carta */}
            </div>
          </div>
        {/* Fin del contenido scrollable */}
    </div>
  );
}

export default HeaderEntrada;
