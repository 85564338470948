import { connect } from 'react-redux';
import { Popover, Transition } from '@headlessui/react';
import { useState, Fragment, useEffect } from 'react';
import { NavLink, Link as RouterLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import Logo from './../../assets/img/mi_tierra_logo.png';

const solutions = [
  {
    name: 'Casos',
    description: 'Measure actions your users take',
    href: '/casos',
  },
  {
    name: 'Servicios',
    description: 'Create your own targeted content',
    href: '/servicios',
  },
  {
    name: 'Nosotros',
    description: 'Keep track of your growth',
    href: '/nosotros',
  },
  {
    name: 'Carreras',
    description: 'Keep track of your growth',
    href: '/carreras',
  },
  {
    name: 'Blog',
    description: 'Keep track of your growth',
    href: '/blog',
  },
  {
    name: 'Contacto',
    description: 'Keep track of your growth',
    href: '/contacto',
  },
];

function Navbar() {
  const [isVisible, setIsVisible] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setIsVisible(prevScrollPos > currentScrollPos || currentScrollPos < 50);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  return (
    <nav
      id="navbar"
      className={`bg-brandColor2 w-full top-0 transition duration-300 ease-in-out z-40 fixed ${
        isVisible ? '' : '-translate-y-full'
      }`}
    >
      <div className="px-4 sm:px-6">
        <div className="px-10 lg:px-20 hidden md:flex justify-between items-center">
          <NavLink to="/menu" className="text-sm font-light text-neutral-200">
            MENÚ EJECUTIVO
          </NavLink>
          <ScrollLink
            to="carta"
            spy={true}
            smooth={true}
            offset={-10}
            duration={800}
            className="cursor-pointer text-sm font-light text-neutral-200"
          >
            LA CARTA
          </ScrollLink>
          <RouterLink to="/" className="text-sm font-light text-neutral-200">
            <img alt='logo' src={Logo} width={80} height={80} className="" />
          </RouterLink>
          <NavLink to="/nosotros" className="text-sm font-light text-neutral-200">
            NOSOTROS
          </NavLink>
          <ScrollLink
            to="contacto"
            spy={true}
            smooth={true}
            offset={-10}
            duration={800}
            className="cursor-pointer text-sm font-light text-neutral-200"
          >
            CONTACTO
          </ScrollLink>
        </div>
        <div className="-ml-4 -mt-2 md:hidden flex flex-wrap items-center justify-center sm:flex-nowrap md:px-14 px-2">
          <RouterLink to="/" className="ml-4 mt-2">
            <img alt='logo' src={Logo} width={80} height={80} className="" />
          </RouterLink>
          <div className="ml-4 mt-2 flex-shrink-0">
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={`
                      ${open ? '' : 'text-opacity-90'}
                      focus:ring-none focus:outline-none`}
                  >
                    {open ? (
                      <i className="bx bx-x text-4xl"></i>
                    ) : (
                      <i className="bx bx-menu text-4xl"></i>
                    )}
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="relative grid gap-8 bg-white p-7 lg:grid-cols-2">
                          {solutions.map((item) => (
                            <RouterLink
                              key={item.name}
                              to={item.href}
                              className="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                            >
                              <div className="ml-4">
                                <p className="text-sm font-medium text-gray-900">{item.name}</p>
                                <p className="text-sm text-gray-500">{item.description}</p>
                              </div>
                            </RouterLink>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </div>
        </div>
      </div>
    </nav>
  );
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Navbar);
