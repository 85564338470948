import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import bg1 from './../../assets/img/bg1.avif'
import bg2 from './../../assets/img/bg2.avif'
import bg3 from './../../assets/img/bg3.avif'

const images = [
  bg1,
  bg2,
  bg3,
];

const Slider = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative w-full h-screen overflow-hidden">
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`Slide ${index + 1}`}
          className={classNames(
            'object-cover absolute w-full h-full transition-opacity duration-1000',
            {
              'opacity-100': index === currentImageIndex,
              'opacity-0': index !== currentImageIndex,
            }
          )}
        />
      ))}
    </div>
  );
};

export default Slider;
