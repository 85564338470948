//IMPORTS
import { useEffect } from "react"

//Layout
import Footer from "components/navigation/Footer"
import Navbar from "components/navigation/Navbar"
import Layout from "hocs/Layout"

//Components
import Header from "components/home/Header.js"
import Guarantee from "components/home/Guarantee.js"
import Carta from "components/home/Carta.js"
import Form from "components/home/Form.js"
import MenuSeccion from "components/home/MenuSeccion.js"

function Home(){
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    return(
        <Layout>
            <Navbar/>
            <div data-scroll-section className='pt-[50px]'>
                <Header />
                <Guarantee />
                <MenuSeccion />
                <Carta />
                <Form />
            </div>
                <Footer/>
        </Layout>
    )
}
export default Home
