//IMPORTS
import { useEffect } from "react"

//Layout
import Footer from "components/navigation/Footer"
import Navbar from "components/navigation/Navbar"
import Layout from "hocs/Layout"

//Components
import HeaderAperitivos from 'components/aperitivos/HeaderAperitivos'

function Aperitivos(){
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    return(
        <Layout>
            <Navbar/>
            <div data-scroll-section className='pt-[100px]'>
                <HeaderAperitivos />
            </div>
                <Footer/>
        </Layout>
    )
}
export default Aperitivos 
