

function Form() {
  return(
    <main className='bg-black pt-24 flex-flex-col items-center'>
      <iframe className="block ml-auto mr-auto max-w-full w-[540px] h-[700px]" src="https://488c1674.sibforms.com/serve/MUIFAMI-jWg9Y6lvx35eJkLH7DDP40W4ILiyn0mEwFlsTrgRiKooDRzFIwvUuOah7FzojL9M12x1B758gSrxWJo0X1Oo7_9prTxFHTaCJi_pdF7VoLRbOWtZVFlvJZA1guiinM6JG85j1lr9VkUwmEN887VMZl7xwwV4wMyMiyi7OEt0p4YQjBiTLdTK3s4Wo6exxPlFWkB3tPr6" frameBorder="0" scrolling="auto" allowFullScreen></iframe>

    </main>

  );
}



export default Form
