import {Link as ScrollLink} from 'react-scroll'
import Slider from './Slider.js'

function Header(){
  return(
    <main>
      <div className='relative flex items-center justify-center'>
        <div className="pt-20 flex flex-col justify-center items-center h-full w-full absolute bg-gradient-to-b from-black/70 via-black/50 to-black z-10">
          <p className='py-2 border-b-4 border-red-800 text-xs xxs:text-xl xs:text-2xl sm:text-4xl md:text-5xl text-neutral-200 px-2 xs:px-10 sm:px-28 md:px-48 text-center font-black'>LO MEJOR DE MI TIERRA SE ENCUENTRA AQUI</p>
          <p className='px-2 xs:px-10 sm:px-20 md:px-48 text-xs xs:text-sm sm:text-md md:text-lg text-neutral-200 text-center py-2'>Ven y disfruta lo mejor de la gastronomia peruana y coctelería clásica. El espacio perfecto para degustar y compartir los mejores platos de <span className='text-red-800 font-bold'>Mi Tierra</span>
          </p>
          {/* CTA ver Carta */}
          <ScrollLink
            to='carta'
            spy={true}
            smooth={true}
            offset={-150}
            duration={800}
            className='my-4 cursor-pointer bg-brandColor2 rounded-md hover:rounded-3xl ease-in-out transition-all duration-500' 
          >
            <p className='px-[16px] py-[10px] md:px-[20px] md:py-[14px] text-center text-xs sm:text-sm font-light text-neutral-200'>Ver Carta</p>
          </ScrollLink>
        </div>
        <Slider />
      </div>
    </main>
  )
}


export default Header
