//IMPORTS
import { useEffect } from "react"

//Layout
import Footer from "components/navigation/Footer"
import Navbar from "components/navigation/Navbar"
import Layout from "hocs/Layout"

//Components
import HeaderPlatos from 'components/platos/HeaderPlatos'

function Platos(){
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    return(
        <Layout>
            <Navbar/>
            <div data-scroll-section className='pt-[100px]'>
                <HeaderPlatos />
            </div>
                <Footer/>
        </Layout>
    )
}
export default Platos
