import bgN from './../../assets/img/bg-nosotros.avif'


function NHeader(){
  return(
    <main>
      <div className='relative flex justify-between'>
        <div className='py-18 sm:py-20 lg:pt-28 px-10 mx-auto  flex flex-col  w-full bg-black'>
          <p className='font-montserrat text-white text-4xl'>SOMOS RESTAURANT MI TIERRA</p>
          <p className='py-4 font-montserrat text-white'>Fundado en 2014 con la misión de llevar lo más exquisito de la gastronomía peruana a nuestros clientes, nuestro restaurante en Santiago se ha convertido en un lugar preciado para los amantes de la buena mesa. Nos distinguimos por el genuino sabor de nuestro ceviche, elaborado con productos frescos y métodos tradicionales que capturan la esencia culinaria del Perú.</p>
          <p className='font-montserrat text-white text-3xl py-2'>Misión</p>
          <p className='font-montserrat text-white py-4'>Nuestra mision es que cada encuentro en nuestro local sea una invitación a descubrir los sabores singulares del Perú, en un ambiente cálido y familiar. Nos enorgullece brindar un servicio atento y personalizado, para que cada comensal se sienta como en su propia casa degustando lo mejor de la cultura del Perú</p>
          <p className='font-montserrat text-white text-3xl py-2'>Visión</p>
          <p className='font-montserrat text-white py-4'>Buscamos brindar las mejores experiencias a nuestro clientes ofreciendo un servicio de calidad que nos lleve a ser el mejor lugar para disfrutar de la tradición culinaria del Perú</p>
        </div>
        <div className='relative w-full overflow-hidden'>
          <div className='z-10 h-full w-full absolute bg-gradient-to-r from-10% from-black to-black/20' />
          <img
            src={bgN}
            className='object-left object-cover absolute w-full h-full'
            alt='bg-aboutus'
          />
        </div>
      </div>
    </main>
  );
}


export default NHeader
